    .dashboard {
        position: relative;
        min-height: 100vh;
    }

    .calendarBarFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        /* Restul stilurilor pentru calendar */
      }
  
  .header, .contracts-list {
    flex: 0 1 auto; /* Permite ajustarea înălțimii în funcție de conținut */
  }
  
  .table-header {
    background-color: #000000;
    color: white;
    /* font-weight: bold; */
    font-size: 12px;
    text-align: center;
    padding: 2px;
    border-bottom: 3px solid #ddd; /* Linie de separare */
  }
  
  .table-header th {
    padding: 5px;
  }
  
  .table-container {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-container th,
  .table-container td {
    border: 1px solid #ddd; /* Bordura tabelului */
  }
  
  .table-container tbody tr:hover {
    background-color: #f2f2f2; /* Efect hover */
  }

  .table-body {
    background-color: #f9f9f9;
    font-size: 13px;
  }

  /*formularul de adăugare a unui contract*/
  .contract-form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spațiu între câmpuri */
  }
  
  .form-group {
    flex: 1 1 calc(50% - 10px); /* Două coloane cu un spațiu între ele */
  }
  
  input {
    width: 100%; /* Asigură-te că input-urile ocupă întreaga lățime a coloanei */
    padding: 8px; /* Spațiu interior */
    margin-bottom: 10px; /* Spațiu între câmpuri */
  }
  
  button {
    margin-top: 10px; /* Spațiu deasupra butonului */
  }
  .contract-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  form {
    width: 100%; /* Formularul ocupă 50% din lățimea paginii */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  button {
    margin-top: 20px; /* Adaugă un spațiu între câmpurile formularului și buton */
  }
  
  .MuiGrid-item {
    padding: 10px; /* Spațiu între coloane */
  }
  
  .mt-5 {
    margin-top: 3rem !important;
    margin-bottom: 2em !important;
}

.css-1ipdo6b {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 600px; */
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
/* .css-2dzjb6 {
  display: none;
} */
