
.custom-link {
  text-decoration: none;
  color: #ffffff;
}

.custom-link:hover {
  color: #ff4800;
}

.custom-link-mobile {
  text-decoration: none;
  color: #000000;
}

.custom-link-mobile:hover {
  color: #ff4800;
}
  
  .custom-button {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .custom-button:hover {
    color: #ff4800;
  }
  