html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.red-row-bg {
  background-color: #ff0000;
  color: white;
  font-weight: 600;
}
#red-row-bg {
  background-color: #ff0000;
  color: white;
  font-weight: 600;
}
.table {
  margin: 15px;
  margin-top: 0;
}
.error {
  color: red;
  font-size: 11px;
}
.success {
  font-size: 20px;
  color: rgb(75, 181, 67);
}

.wrapper {
  width: 115vw;
}

.add-car-form-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* width: 100vw;
  height: 50vh; */
  /* margin-top: 500px; */
}
@media screen and (max-width: 768px) {
  .add-car-form-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* max-width: 100vw;
    height: 65vh; */
    /* margin-top: 450px; */
  }
}
.add-car-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250vw;
  /* height: 45vh;
  margin-top: 500px;
  margin-left: 0px; */
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .add-car-list-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 290vw;
    /*  height: 65vh; */
    margin-top: 70px;
    /* margin-left: 0px; */
  }
}

.change-password-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 30vh;
  margin-top: 40px;
}
.contracts-list-form-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  max-width: 350vw;
  /* height: 35vh; */
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .contracts-list-form-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 550vw;
    /* height: 265vh; */
    margin-top: -20px;
    /* margin-left: 0px; */
  }
}
.filter_contracts-list-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
  margin-top: 10px;
  /* border: solid 1px red; */
}
@media screen and (max-width: 768px) {
  .filter_contracts-list-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 20vh;
    margin-top: 5px;
    /* border: solid 1px red; */
  }
}

.users-list {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 220vw;
  height: 30vh;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .users-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290vw;
    /* height: 65vh; */
    margin-top: 45px;
    margin-left: 0px;
  }
}

.add-user-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 60vh;
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .add-user-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /* height: 65vh; */
    /* margin-top: 70px; */
    /* margin-left: 0px; */
  }
}

.legenda {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: center;
}

.add-car-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.add-car-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.add-car-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.container-fluid {
  margin-top: 30px;
  /* padding-bottom: 30px; */
}

#report {
}

.wcdiv {
  position: absolute;
  margin-top: 20px;
}
.wcspan {
  position: absolute;
  white-space: pre;
  color: #000000;
  font-size: 12pt;
}
.wcimg {
  position: absolute;
}
.wcsvg {
  position: absolute;
}
.wcpage {
  position: relative;
  margin: 10pt auto 10pt auto;
  overflow: hidden;
}

@media print {
  body {
    margin: 0pt;
    padding: 0pt;
  }
  .wcpage {
    page-break-after: always;
    margin: 0pt;
    padding: 0pt;
  }
}
.wctext001 {
  font-family: "Calibri";
  font-style: normal;
  font-weight: normal;
}
.wctext002 {
  font-family: "Calibri";
  font-style: normal;
  font-weight: bold;
}
.wctext003 {
  font-family: "Arial";
  font-style: normal;
  font-weight: bold;
}
.wctext004 {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
}
.wctext005 {
  font-family: "Calibri";
  font-style: italic;
  font-weight: bold;
}
.printButton {
  color: white;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 4px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}

.printme {
  display: none;
}
@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
}

.imageSize {
  max-width: 70px;
  max-height: 70px;
}

.rowGray td {
  background-color: #ff0000;
  color: rgb(123, 122, 122);
  font-weight: 600;
}
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem -0.75rem 4rem;
  border: solid #dee2e6;
  border-width: 1px 0 0;
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 1px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  max-width: 350px;
}
@media screen and (max-width: 768px) {
  .bd-example {
    position: relative;
    padding: 1rem;
    margin: 1rem -0.75rem -4rem;
    border: solid #dee2e6;
    border-width: 1px 0 0;
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 1px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    max-width: 350px;
  }
}

.table {
  margin-left: auto;
  margin-bottom: auto;
}

/* calendar */
.calendarBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
  /* list-style: none; */
}

.dateFree {
  width: 30px;
  text-align: center;
  font-size: 12px;
  background-color: #ffffff;
  color: #198754;
  border-radius: 4px;
  border: 1px solid #198754;
  padding: 5px;
  margin-left: 1px;
  margin-right: 1px;
}
.dateBusy {
  width: 30px;
  text-align: center;
  font-size: 12px;
  background-color: #df0000;
  color: white;
  border-radius: 4px;
  padding: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.calendar-card {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.listTitle {
  align-items: center;
  font-size: large;
  font-weight: bold;
  padding-top: 20px;
}
