.change-password-form-container {
    width: 400px;
    margin: auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .error-border {
    border-color: #e74c3c !important;
  }
  
  .error-message {
    color: #e74c3c;
    font-size: 0.875rem;
  }
  
  .success-message {
    color: #27ae60;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-top: 1rem;
  }
  
  .submit-button {
    width: 100%;
    background-color: #4CAF50;
    border: none;
    padding: 0.5rem;
    font-weight: bold;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  .change-password-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem; /* Adaugă spațiu față de antet */
  }
  
  .page-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem; /* Adaugă spațiu față de formular */
  }
  
  .change-password-form-container {
    width: 400px;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 1rem; /* Separa formularul de titlu */
  }
  .title-and-form-container {
    margin: 5rem auto; /* Asigură spațiu între titlu și formular */
    max-width: 500px; /* Setează o lățime maximă pentru container */
  }
  
  .change-password-form-container {
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
    